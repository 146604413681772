<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form change-password" @submit.prevent="handleSubmit()">
      <lims-block>
        <modal-show-announcement
          slot="blockTitle"
          ref="showAnnouncementModal"
          :announcement="formData.announcement"
        ></modal-show-announcement>
        <h4 class="title" slot="blockTitle">{{ $t('blocks.tittle.password') }}</h4>
        <div slot="blockContent">
          <div class="md-layout confirm-password">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field :model="formData" :schema="changePasswordSchema.information" field="currentPassword">
                <md-input slot="field" v-model="formData.currentPassword" type="password" autocomplete="off"></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field :model="formData" :schema="changePasswordSchema.information" field="newPassword">
                <md-input slot="field" v-model="formData.newPassword" type="password" autocomplete="off"></md-input>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-50">
              <p>{{ $t('page/myAccount/password/passwordStrength') }}</p>
            </div>
            <div v-if="formData.newPassword" class="md-layout-item md-size-50 md-small-size-50">
              <md-button
                v-if="passwordMeterPercent < 20"
                class="md-layout-item md-danger md-size-100 md-small-size-100"
                >{{ $t('page/auth/ResetPassword/passwordStrength/veryWeak') }}</md-button
              >
              <md-button
                v-else-if="passwordMeterPercent < 40"
                class="md-layout-item md-rose md-size-100 md-small-size-100"
                >{{ $t('page/auth/ResetPassword/passwordStrength/weak') }}</md-button
              >
              <md-button
                v-else-if="passwordMeterPercent < 60"
                class="md-layout-item md-warning md-size-100 md-small-size-100"
                >{{ $t('page/auth/ResetPassword/passwordStrength/medium') }}</md-button
              >
              <md-button
                v-else-if="passwordMeterPercent <= 80"
                class="md-layout-item md-success md-size-100 md-small-size-100"
                >{{ $t('page/auth/ResetPassword/passwordStrength/strong') }}</md-button
              >
              <md-button
                v-else-if="passwordMeterPercent <= 100"
                class="md-layout-item md-info md-size-100 md-small-size-100"
                >{{ $t('page/auth/ResetPassword/passwordStrength/veryStrong') }}</md-button
              >
            </div>
          </div>
          <div class="check-password-strength-group-my-account">
            <lims-password-strength :password-string="formData.newPassword"></lims-password-strength>
          </div>
          <div class="md-layout lims-form-row confirm-password">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field :model="formData" :schema="changePasswordSchema.information" field="confirmPassword">
                <md-input slot="field" v-model="formData.confirmPassword" type="password" autocomplete="off"></md-input>
              </lims-field>
            </div>
          </div>
        </div>
      </lims-block>
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <LimsFormCancel :redirect-url="'/my-account'" />
          <md-button
            @click="onSave()"
            class="md-button md-primary lims-form-button md-theme-default"
            :disabled="isDisableSaveButton"
          >
            {{ $t('global/button/button.save') }}
          </md-button>
        </div>
      </div>
      <!-- End action form -->
    </form>
  </ValidationObserver>
</template>
<script>
import { FormMixins, TabMixins } from '@/core/mixins';
import LimsPasswordStrength from '@/components/Lims/LimsPasswordStrength';
import { getPasswordSchema } from '@/schemas/password.schema';
import { MyAccountService } from '@/services';
import { mapState } from 'vuex';
import ModalShowAnnouncement from '@/components/Lims/modals/ModalShowAnnouncement';
import passwordMeter from 'password-meter';
import { deleteCookie, getCookie } from '@/core/helpers';
import { LOCAL_STORAGE_KEYS } from '@/core/constants';

export default {
  name: 'dropdown-item-form',
  components: {
    ModalShowAnnouncement,
    LimsPasswordStrength,
  },
  mixins: [FormMixins, TabMixins],
  data() {
    return {
      formData: {
        currentPassword: null,
        newPassword: null,
        confirmPassword: null,
        announcement: this.$t('entities/MyAccount/ChangePassword/SuccessMessage'),
      },
      passwordMeter: null,
      passwordMeterPercent: null,
    };
  },
  created() {
    this.passwordMeter = new passwordMeter.PasswordMeter();
  },
  computed: {
    ...mapState('auth', {
      userId: (state) => state.user.userId,
    }),
    changePasswordSchema() {
      return getPasswordSchema(this.formData.newPassword);
    },
    isDisableSaveButton() {
      return !this.formData.currentPassword || !this.formData.newPassword || !this.formData.confirmPassword;
    },
    newPassword() {
      return this.formData.newPassword;
    },
  },
  watch: {
    newPassword() {
      this.passwordMeterPercent = this.passwordMeter.getResult(this.formData.newPassword).percent;
    },
  },
  methods: {
    onSave() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          try {
            const res = await MyAccountService.changePassword(
              this.userId,
              this.formData.currentPassword,
              this.formData.newPassword,
            );

            if (res.data && res.data.NewPassword?.length > 0) {
              return this.$alertError(this.$t('page/myAccount/password/error/newPassword'));
            }

            if (res.data && res.data.CurrentPassword?.length > 0) {
              return this.$alertError(this.$t('page/myAccount/password/error/currentPassword'));
            }

            if (res.error === 'CognitoPasswordIncorrect') {
              return this.$alertError(this.$t(`global/errorServerSide/CognitoPasswordIncorrect`));
            }

            if (res.err || res.error) {
              return this.$alertError(res.error);
            }

            this.$refs.showAnnouncementModal.open();
            setTimeout(() => {
              this.$refs.showAnnouncementModal.close();
              // prevent show session expired modal at login page
              if (getCookie(LOCAL_STORAGE_KEYS.SESSION_EXPIRED)) {
                deleteCookie(LOCAL_STORAGE_KEYS.SESSION_EXPIRED);
              }
              this.$store.dispatch('auth/DO_LOGOUT');
            }, 3000);
          } catch (error) {
            this.$alertError(error + 'err');
          }
        } else {
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.change-password {
  width: 500px;
  margin: 0 auto;
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
  .check-password-strength-group-my-account {
    margin: 0 auto 24px;
    display: table;
  }
}
</style>
